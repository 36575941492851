import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Toaster from "@meforma/vue-toaster";
import BootstrapVue3 from "bootstrap-vue-3";
import VueSplide from "@splidejs/vue-splide";
import VueGtag from "vue-gtag";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import "./assets/custom.scss";
import TextCarrousel from './components/Layouts/TextCarrousel';


const app = createApp(App);

app.component('TextCarrousel', TextCarrousel);

app.use(VueGtag,{
  config: {
    id: "G-QQZZB40LJV",
    params: {
      router: router,
    },
  },
});




app.use(router)
  .use(store)
  .use(Toaster)
  
app.use(VueAxios, axios);
app.use(VueSplide);
app.use(BootstrapVue3).mount("#app");
